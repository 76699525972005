import React, { Component } from "react"
import styled from "styled-components"

import ProductCardList from "../ProductCardList"
import AllProductIcon from "../Icons/AllProductIcon"
import VerticalRoofIcon from "../Icons/VerticalRoofIcon"
import FrameRoofIcon from "../Icons/FrameRoofIcon"
import RegularRoofIcon from "../Icons/RegularRoofIcon"

const TabWrap = styled.div`
  width: 100%;
  max-width: 890px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
	scroll-behavior: smooth;
  margin: 0 auto 30px auto;
  @media (min-width: 768px) {
    margin: 60px auto 30px auto;
    overflow-x: unset;
  }
`

const TabItem = styled.div`
  position: relative;
  text-align: center;
  background: #fff;
  width: 100%;

  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  border-top: 1px solid #676b79;
  color: ${props => props.color};
  border-bottom: 1px solid ${props => props.color};

  padding: 15px 0px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  @media (min-width: 768px) {
    padding: 30px 0px;
  }
  &:hover {
    cursor: pointer;
  }
`

const TabImage = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`

const TabItemText = styled.div`
  text-align: center;
  width: 100%;
  padding: 0px 10px;
  white-space: nowrap;
`

class TabProductCardList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAll: true,
      isVerticalRoof: false,
      isAFrameRoof: false,
      isRegularRoof: false,
      productData: this.props.data,
    }
    this.handleAll = this.handleAll.bind(this)
    this.handleVerticalRoof = this.handleVerticalRoof.bind(this)
    this.handleAFrameRoof = this.handleAFrameRoof.bind(this)
    this.handleRegularRoof = this.handleRegularRoof.bind(this)
  }
  handleAll(data) {
    this.setState({
      isAll: true,
      isVerticalRoof: false,
      isAFrameRoof: false,
      isRegularRoof: false,
      productData: data,
    })
		this.scrollToTarget("categori_list", "category_all");
  }
  handleVerticalRoof(data) {
    let verticalRoofData = []
    data.forEach(edge => {
      if (edge.node.productRoofType.name === "Vertical Roof") {
        verticalRoofData.push(edge)
      }
    })
    this.setState({
      isAll: false,
      isVerticalRoof: true,
      isAFrameRoof: false,
      isRegularRoof: false,
      productData: verticalRoofData,
    });
		this.scrollToTarget("categori_list", "category_vertical");
  }
  handleAFrameRoof(data) {
    let aFrameRoofData = []
    data.forEach(edge => {
      if (edge.node.productRoofType.name === "A-Frame Roof") {
        aFrameRoofData.push(edge)
      }
    })
    this.setState({
      isAll: false,
      isVerticalRoof: false,
      isAFrameRoof: true,
      isRegularRoof: false,
      productData: aFrameRoofData,
    });
		this.scrollToTarget("categori_list", "category_frame");
  }
  handleRegularRoof(data) {
    let regularRoofData = []
    data.forEach(edge => {
      if (edge.node.productRoofType.name === "Regular Roof") {
        regularRoofData.push(edge)
      }
    })
    this.setState({
      isAll: false,
      isVerticalRoof: false,
      isAFrameRoof: false,
      isRegularRoof: true,
      productData: regularRoofData,
    });
		this.scrollToTarget("categori_list", "category_regular");
  }

  scrollToTarget(containerId, targetId) {
    const container = document.getElementById(containerId)
    const targetDiv = document.getElementById(targetId)
    container.scrollLeft = targetDiv.offsetLeft - 4
  }

  render() {
    const { data, category, location } = this.props
    const {
      isAll,
      isVerticalRoof,
      isAFrameRoof,
      isRegularRoof,
      productData,
    } = this.state
    return (
      <div>
        <TabWrap id="categori_list">
          <TabItem
            id="category_all"
            color={isAll ? "#ED602B" : "#676B79"}
            onClick={() => this.handleAll(data)}
          >
            <TabImage top="-33px" left="calc(50% - 38px)">
              <AllProductIcon
                fill={isAll ? "#ED602B" : "white"}
                lineFill={isAll ? "#b54318" : "#686c79"}
              />
            </TabImage>
            <TabItemText>All {category}</TabItemText>
          </TabItem>
          <TabItem
            id="category_vertical"
            color={isVerticalRoof ? "#ED602B" : "#676B79"}
            onClick={() => this.handleVerticalRoof(data)}
          >
            <TabImage top="-21px" left="calc(50% - 60.6px)">
              <VerticalRoofIcon fill={isVerticalRoof ? "#ED602B" : "#686c79"} />
            </TabImage>
            <TabItemText>Vertical Roof {category}</TabItemText>
          </TabItem>
          <TabItem
            id="category_frame"
            color={isAFrameRoof ? "#ED602B" : "#676B79"}
            onClick={() => this.handleAFrameRoof(data)}
          >
            <TabImage top="-21px" left="calc(50% - 60.6px)">
              <FrameRoofIcon fill={isAFrameRoof ? "#ED602B" : "#686c79"} />
            </TabImage>
            <TabItemText>A-Frame Roof {category}</TabItemText>
          </TabItem>
          <TabItem
            id="category_regular"
            color={isRegularRoof ? "#ED602B" : "#676B79"}
            onClick={() => this.handleRegularRoof(data)}
          >
            <TabImage top="-18px" left="calc(50% - 60.6px)">
              <RegularRoofIcon fill={isRegularRoof ? "#ED602B" : "#686c79"} />
            </TabImage>
            <TabItemText>Regular Roof {category}</TabItemText>
          </TabItem>
        </TabWrap>
        <div>
          <ProductCardList data={productData} location={location} />
        </div>
      </div>
    )
  }
}

export default TabProductCardList
