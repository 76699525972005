import React from "react"
import WidthIcon from "../Icons/WidthIcon"
import LengthIcon from "../Icons/LengthIcon"
import HeightIcon from "../Icons/HeightIcon"
import SecondStepBar from "../VerticalStepBar/SecondStepBar"
// import { Dimensions, DimensionItem, Value, SideGrid } from "../FormStyle"
import styled from "styled-components"

import { Dimensions, DimensionItem, DimensionSymbol, FormCheckGrid, FormCheckItmGrid, ControlLabel, StepGrid, StepLeftGrid, StepRightGrid} from "../FormStyle"
import { Field, Radio, FormListCheck, SectionTitle  } from '../Section'

const Wrapper = styled.div`
  display: ${props => (props.hidden ? "none" : "block")};
`

class SecondStep extends React.Component {
  render() {
    const { hidden } = this.props

    return (
      <Wrapper className="form-group" hidden={hidden}>
        <SectionTitle color="#fff" textAlign="center">Better Understand your Garages & Get a Quick Quote</SectionTitle>
        <StepGrid>
          <StepLeftGrid><SecondStepBar fill="white" /></StepLeftGrid>
          <StepRightGrid>
            <ControlLabel color="#fff">Choose the Dimensions</ControlLabel>
            <Dimensions>
              <DimensionItem>
                <DimensionSymbol className='demension'>
                  <span className='icon'><WidthIcon /></span>
                  <span className='text'>Width</span>
                </DimensionSymbol>
                <Field><input type="number" name="width" onChange={this.props.handleChange} className="form-control form-outline form-primary form-rounded" /></Field>
              </DimensionItem>

              <DimensionItem>
                <DimensionSymbol className='demension'>
                  <span className='icon'><LengthIcon /></span>
                  <span className='text'>Length</span>
                </DimensionSymbol>
                <Field><input type="number" name="length"  onChange={this.props.handleChange} className="form-control form-outline form-primary form-rounded" /></Field>
              </DimensionItem>

              <DimensionItem> 
                <DimensionSymbol className='demension'>
                  <span className='icon'><HeightIcon /></span>
                  <span className='text'>Height</span>
                </DimensionSymbol>
                <Field><input type="number" name="height" onChange={this.props.handleChange} className="form-control form-outline form-primary form-rounded" /></Field>
              </DimensionItem>
            </Dimensions>
            
            <FormCheckGrid>
              <FormCheckItmGrid>
                <ControlLabel color="#fff">Back Ends</ControlLabel>
                <FormListCheck>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="backend" value="Open" onChange={this.props.handleChange}/> Open{" "}</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="backend" value="Fully Enclose" onChange={this.props.handleChange}/> Fully Enclose</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="backend" value="Gable Ends" onChange={this.props.handleChange}/> Gable Ends</label></Radio>
                </FormListCheck>
              </FormCheckItmGrid>

              <FormCheckItmGrid>
                <ControlLabel color="#fff">Front Ends</ControlLabel>
                <FormListCheck>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="frontend" value="Open" onChange={this.props.handleChange}/>Open{" "}</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="frontend" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="frontend" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label></Radio>
                </FormListCheck>
              </FormCheckItmGrid>

              <FormCheckItmGrid>
                <ControlLabel color="#fff">Left Side</ControlLabel>
                <FormListCheck>
                  <Radio className='radio' color='#fff'><label> <input type="radio" className="form-control" name="leftside" value="Open" onChange={this.props.handleChange}/>Open{" "}</label></Radio>
                  <Radio className='radio' color='#fff'><label> <input type="radio" className="form-control" name="leftside" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label></Radio>
                  <Radio className='radio' color='#fff'><label> <input type="radio" className="form-control" name="leftside" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label></Radio>
                </FormListCheck>
              </FormCheckItmGrid>

              <FormCheckItmGrid>
                <ControlLabel color="#fff">Right Side</ControlLabel>
                <FormListCheck>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="rightside" value="Open" onChange={this.props.handleChange} />Open{" "}</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="rightside" value="Fully Enclose" onChange={this.props.handleChange} />Fully Enclose</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="rightside" value="Gable Ends" onChange={this.props.handleChange} />Gable Ends</label></Radio>
                </FormListCheck>
              </FormCheckItmGrid>
            </FormCheckGrid>
          </StepRightGrid>
        </StepGrid>
      </Wrapper>
    )
  }
}

export default SecondStep
