import React from 'react'

const AllProductIcon = (props) =>(
	<svg xmlns="http://www.w3.org/2000/svg" width="77.038" height="55.001" viewBox="0 0 77.038 55.001">
	  <g id="Group_2369" data-name="Group 2369" transform="translate(-446.5 -191.278)">
	    <g id="Group_2355" data-name="Group 2355" transform="translate(12085.169 14252)">
	      <path id="Path_15478" data-name="Path 15478" d="M-11595.726-14039.5l-42.406-6.552,18.587-7.25Z" transform="translate(0 -1.896)" fill={props.fill} stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	      <path id="Path_15479" data-name="Path 15479" d="M-11570.418-14060.222l17.634,9.7-33.314,9.126-23.216-13.814Z" transform="translate(-10.208)" fill={props.fill} stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	      <path id="Path_15480" data-name="Path 15480" d="M-11572.458-14002.693l33.593-21.295-.816-22.945-32.776,9.053Z" transform="translate(-23.267 -3.528)" fill={props.fill} stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	      <path id="Path_15481" data-name="Path 15481" d="M-11638.132-14015.54l42.377,13.747v-35.174l-42.414-6.5Z" transform="translate(0 -4.435)" fill={props.fill} stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	    </g>
	    <path id="Path_15487" data-name="Path 15487" d="M-11625.263-14029.525l20.9,2.48-.7,24.438-21.045-6.91Z" transform="translate(12080.873 14243.821)" fill={props.fill} stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	    <g id="Group_2370" data-name="Group 2370">
	      <path id="Path_15497" data-name="Path 15497" d="M-11625.262-14029.525l20.811,2.356Z" transform="translate(12080.262 14263.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	      <g id="Group_2369-2" data-name="Group 2369">
	        <path id="Path_15488" data-name="Path 15488" d="M-11625.262-14029.525l20.4,2.366Z" transform="translate(12081.262 14245.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15489" data-name="Path 15489" d="M-11624.76-14029.548l20.785,2.438Z" transform="translate(12080.262 14247.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15490" data-name="Path 15490" d="M-11624.911-14029.5l20.909,2.374Z" transform="translate(12080.262 14249.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15491" data-name="Path 15491" d="M-11624.955-14029.54l20.937,2.452Z" transform="translate(12080.262 14251.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15492" data-name="Path 15492" d="M-11625.064-14029.491l20.944,2.405Z" transform="translate(12080.262 14253.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15493" data-name="Path 15493" d="M-11625.137-14029.449l21,2.31Z" transform="translate(12080.262 14255.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15494" data-name="Path 15494" d="M-11625.262-14029.525l21.031,2.39Z" transform="translate(12080.262 14257.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15495" data-name="Path 15495" d="M-11625.262-14029.525l20.951,2.354Z" transform="translate(12080.262 14259.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15496" data-name="Path 15496" d="M-11625.262-14029.525l21,2.405Z" transform="translate(12080.262 14261.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	        <path id="Path_15498" data-name="Path 15498" d="M-11617.949-14029.007l13.454,1.831Z" transform="translate(12080.262 14265.821)" fill="none" stroke={props.lineFill} strokeLinecap="square" strokeLinejoin="round" strokeWidth="1"/>
	      </g>
	    </g>
	  </g>
	</svg>
)

export default AllProductIcon