import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import axios from "axios"
import FirstStep from "./first-step"
import SecondStep from "./second-step"
import ThirdStep from "./third-step"
import * as EmailValidator from "email-validator"
import { Triangle } from "../FormStyle"
import { phone } from "phone"
import { postcodeValidator } from "postcode-validator"
import PrimaryButton from "../../components/Button/PrimaryButton"
import {
  Container,
  ExploreMore,
  ExploreItemMore,
  SectionPage,
  SectionTitle,
} from "../../components/Section"

const ImageWrap = styled.div`
  display: ${props => props.display};
  & img {
    width: 100%;
  }
`
const SectionPages = styled(SectionPage)`
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    0deg,
    rgba(112, 112, 112, 1) 0.1em,
    transparent 6.04px
  );
  background-size: 1em 1em;
  &:before {
    display: block;
    content: "";
    opacity: 0.98;
    width: 100%;
    height: 100%;
    background: #062c44;
    @media (min-width: 768px) {
      opacity: 0.98;
      background: linear-gradient(
        90deg,
        rgb(30, 65, 86) 0%,
        rgb(30, 65, 86) 50%,
        rgb(6, 44, 68) 50%,
        rgb(6, 44, 68) 100%
      );
    }
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  @media (min-width: 768px) {
    margin: 0 -40px;
  }
`
const GridLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 30px;
  @media (min-width: 768px) {
    padding: 0 40px;
    flex: 0 0 50%;
    max-width: 50%;
  }
`
const GridRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 30px;
  @media (min-width: 768px) {
    padding: 0 40px;
    flex: 0 0 50%;
    max-width: 50%;
  }
`
const StepWrap = styled.div`
  margin: 0;
`
const SectionCta = styled.div`
  padding: 0;
  margin: 0 0 30px;
`
const SectionCtaBody = styled.div`
  padding: 0;
  form {
    margin-bottom: 0;
  }
`
const SectionCtaFooter = styled.div``
const House3D = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    max-width: 75%;
  }
`

class ProductCustomBuildingForm extends React.Component {
  constructor(props) {
    super(props)
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      rooftype: "Vertical Roof",
      backend: "",
      frontend: "",
      leftside: "",
      rightside: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      zipcode: "",
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      zipcodeRequire: false,
      errors: {},
    }
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this._next = this._next.bind(this)
    this._previous = this._previous.bind(this)
  }

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
    if (name === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (name === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (name === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (name === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (name === "zipcode") {
      this.setState({
        zipcodeRequire: false,
      })
    }
  }

  // Trigger an alert on form submission
  handleSubmit = event => {
    let currentStep = this.state.currentStep
    const { buildingType, location } = this.props
    event.preventDefault()
    let errors = {}
    if (this.state.first_name === "") {
      errors.fNameRequire = true
    }

    if (this.state.last_name === "") {
      errors.lNameRequire = true
    }

    if (this.state.email === "") {
      errors.emailRequire = true
    } else if (!EmailValidator.validate(this.state.email)) {
      errors.emailInvalid = true
    }

    if (this.state.phone_no === "") {
      errors.phoneRequire = true
    } else if (!phone(this.state.phone_no, { country: "USA" }).isValid) {
      errors.phoneInvalid = true
    }

    if (this.state.zipcode === "") {
      errors.zipcodeRequire = true
    } else if (!postcodeValidator(this.state.zipcode, "US")) {
      errors.zipcodeInvalid = true
    }

    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      const comment =
        "Building Type: " +
        buildingType +
        "; Roof Style: " +
        this.state.rooftype +
        "; Width: " +
        this.state.width +
        "; Length: " +
        this.state.length +
        "; Height: " +
        this.state.height +
        "; Back End: " +
        this.state.backend +
        "; Front End: " +
        this.state.frontend +
        "; Left Side: " +
        this.state.leftside +
        "; Right Side: " +
        this.state.rightside
      const data = {
        source: location.origin,
        form: "product custom building form",
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        zipcode: this.state.zipcode,
        comment: comment,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          currentStep = currentStep + 1
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            currentStep: currentStep,
          })
          navigate("/thank-you-request-quote/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }

  _next() {
    let currentStep = this.state.currentStep
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 3 ? 4 : currentStep + 1
    this.setState({
      currentStep: currentStep,
    })
  }

  _previous() {
    let currentStep = this.state.currentStep
    this.setState({
      currentStep: currentStep - 1,
    })
  }

  get previousButton() {
    if (this.state.currentStep === 1) return null
    return (
      <button type="button" onClick={this._previous} className="btn-lg">
        <PrimaryButton text="Previous" />
      </button>
    )
  }

  get nextButton() {
    let currentStep = this.state.currentStep
    // If the current step is not 3, then render the "next" button
    if (currentStep < 3) {
      return (
        <button type="button" onClick={this._next} className="btn-lg">
          <PrimaryButton text="Next" />
        </button>
      )
    } else if (currentStep === 3) {
      return (
        <button
          type="button"
          form="product-form"
          onClick={this.handleSubmit}
          className="btn-lg"
        >
          <PrimaryButton text="Submit" />
        </button>
      )
    }

    return null
  }

  render() {
    const { data, category } = this.props
    const { currentStep, errors } = this.state

    return (
      <SectionPages
        ept="80px"
        epb="80px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
      >
        <Container>
          <Grid>
            <GridLeft>
              <SectionCta>
                <SectionCtaBody>
                  <form id="product-form">
                    <StepWrap>
                      <FirstStep
                        handleChange={this.handleChange}
                        rooftype={this.state.rooftype}
                        data={data}
                        hidden={currentStep !== 1}
                      />
                      <SecondStep
                        hidden={currentStep !== 2}
                        handleChange={this.handleChange}
                        rooftype={this.state.rooftype}
                        backend={this.state.backend}
                        frontend={this.state.frontend}
                        rightside={this.state.rightside}
                        leftside={this.state.leftside}
                      />
                      <ThirdStep
                        hidden={currentStep !== 3}
                        handleChange={this.handleChange}
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        email={this.state.email}
                        phone_no={this.state.phone_no}
                        zipcode={this.state.zipcode}
                        errors={errors}
                        fNameRequire={this.state.fNameRequire}
                        lNameRequire={this.state.lNameRequire}
                        emailRequire={this.state.emailRequire}
                        phoneRequire={this.state.phoneRequire}
                        zipcodeRequire={this.state.zipcodeRequire}
                      />
                    </StepWrap>
                  </form>
                </SectionCtaBody>
                <SectionCtaFooter>
                  <ExploreMore ml="-10px" mr="-10px">
                    <ExploreItemMore pl="10px" pr="10px">
                      {this.previousButton}
                    </ExploreItemMore>
                    <ExploreItemMore pl="10px" pr="10px">
                      {this.nextButton}
                    </ExploreItemMore>
                  </ExploreMore>
                </SectionCtaFooter>
              </SectionCta>
              <Triangle />
            </GridLeft>
            <GridRight>
              <SectionCta>
                <SectionTitle
                  color="#fff"
                  textAlign="center"
                >{`${this.state.rooftype} ${category}`}</SectionTitle>
                <SectionCtaBody>
                  <House3D>
                    {data.features.map((item, i) => {
                      return (
                        <ImageWrap
                          key={i}
                          display={
                            item.roof === this.state.rooftype ? "block" : "none"
                          }
                        >
                          <img src={item.image} alt="features" />
                        </ImageWrap>
                      )
                    })}
                  </House3D>
                  <ExploreMore>
                    <a
                      href="https://probuiltsteel.sensei3d.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="btn-lg"
                    >
                      <PrimaryButton text="3D Estimator" />
                    </a>
                  </ExploreMore>
                </SectionCtaBody>
              </SectionCta>
            </GridRight>
          </Grid>
        </Container>
      </SectionPages>
    )
  }
}

export default ProductCustomBuildingForm
