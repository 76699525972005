import React from 'react'
import styled from 'styled-components'
import ThirdStepBar from "../VerticalStepBar/ThirdStepBar"
import { StepGrid, StepLeftGrid, StepRightGrid, ControlLabel, CustomValidationMessage, RequiredTriangle, FormRow, FormTwoCol, } from "../FormStyle"
import { Field, SectionTitle } from '../Section'

const Wrapper = styled.div`
  display: ${props => (props.hidden ? "none" : "block")};
`

class ThirdStep extends React.Component {
  render() {
    const { errors, hidden } = this.props

    const {
      fNameRequire,
      lNameRequire,
      emailRequire,
      emailInvalid,
      phoneRequire,
      phoneInvalid,
      zipcodeRequire,
      zipcodeInvalid,
    } = errors

    return (
      <Wrapper className="form-group" hidden={hidden}>
        <SectionTitle color="#fff" textAlign="center">Better Understand your Garages & Get a Quick Quote</SectionTitle>
        <StepGrid>
          <StepLeftGrid><ThirdStepBar fill="white" /></StepLeftGrid>
          <StepRightGrid>
            <ControlLabel color="#fff">Tell us your more information so our customer care executives can call
              you and describe our services in a better way.</ControlLabel>
            <FormRow>
              <FormTwoCol>
                <Field>
                  <input className="form-control form-outline form-primary form-rounded" type="text" name="first_name" placeholder="Your First Name" onChange={this.props.handleChange} pattern="[A-Za-z\s]+$" />
                  <CustomValidationMessage display={fNameRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                </Field>
              </FormTwoCol>

              <FormTwoCol>
                <Field>
                  <input className="form-control form-outline form-primary form-rounded" type="text" name="last_name" placeholder="Your Last Name" onChange={this.props.handleChange} pattern="[A-Za-z\s]+$" />
                  <CustomValidationMessage display={lNameRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                </Field>
              </FormTwoCol>
              <FormTwoCol>
                <Field>
                  <input className="form-control form-outline form-primary form-rounded" type="email" name="email" placeholder="Your Email" onChange={this.props.handleChange} />
                  <CustomValidationMessage display={emailRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                  <CustomValidationMessage display={emailInvalid ? "block" : "none"}><RequiredTriangle /><span>Please input valid email address</span></CustomValidationMessage>
                </Field>
              </FormTwoCol>
              <FormTwoCol>
                <Field>
                  <input className="form-control form-outline form-primary form-rounded" type="text" name="phone_no" placeholder="Phone Number" onChange={this.props.handleChange} pattern="[0-9]{10}" />
                  <CustomValidationMessage display={phoneRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                  <CustomValidationMessage display={phoneInvalid ? "block" : "none"}><RequiredTriangle /><span>Please input valid phone number</span></CustomValidationMessage>
                </Field>
              </FormTwoCol>
              <FormTwoCol>
                <Field>
                  <input className="form-control form-outline form-primary form-rounded" type="number" name="zipcode" placeholder="Zip Code" onChange={this.props.handleChange} pattern="[0-9]+$" />
                  <CustomValidationMessage display={zipcodeRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                  <CustomValidationMessage display={zipcodeInvalid ? "block" : "none"}><RequiredTriangle /><span>Please input valid Zipcode</span></CustomValidationMessage>
                </Field>
              </FormTwoCol>
            </FormRow>
          </StepRightGrid>
        </StepGrid>

      </Wrapper>
    )
  }
}

export default ThirdStep
