import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SectionButton from "../Button/SectionButton"

const Wrapper = styled.div`
	background: #FFFFFF;
	border: 1px solid #98DFFF;
	box-shadow: 0px 15px 45px rgba(152, 223, 225, 0.2);
	border-radius: 10px;
	padding: 38px;
	text-align: center;

	& h3 {
		font-size: 44px;
		line-height: 53px;
		margin-bottom: 20px;
	}

	& p {
		font-size: 18px;
		font-weight: bold;
		line-height: 29px;
		color: #676B79;
		width: 300px;
		margin: auto;
	}

	& .gatsby-image-wrapper {
		width: 300px;
		margin: auto;
		margin-top: 21px;
		margin-bottom: 20px;
	}
	@media(max-width: 400px) {
		& p {
			width: 250px;
		}
		& .gatsby-image-wrapper {
			width: 250px;
		}
	}
`
const HouseImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "3d-house.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid}  alt='3D Design Tool'/>
}

const DesignPlan = () => {
	return(
		<Wrapper>
			<h3>Design & Plan The Building</h3>
			<p>You can use 3D Design Tool to Customize & Visualize your Building.</p>
			<HouseImage />
			<SectionButton width="320px"><a href="https://probuiltsteel.sensei3d.com/" target="_blank" rel="noreferrer">3D Estimator</a></SectionButton>
		</Wrapper>
	)
}

export default DesignPlan