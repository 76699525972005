import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  SectionPage,
  SectionHeading,
  MainPanel,
  LeftPanel,
  RightPanel,
  BreadCrumb,
  MarkdownContent,
  Container,
} from "../components/Section"
import { SidebarSticky } from "../components/Sidebar"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import TabProductCardList from "../components/TabProductCardList"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import ProductCustomBuildingForm from "../components/ProductCustomBuildingForm"
import generateHTML from "../utils/generateHTML"
import QuickContactForm from "../components/QuickContactForm"
import ProductCardList from "../components/ProductCardList"

class ProductCategoryTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulProductCategoryLanding
    const currentData = this.props.data.allContentfulProduct.edges.filter(
      edge =>
        edge.node.productCategory &&
        edge.node.productCategory.name &&
        edge.node.productRoofType &&
        edge.node.productRoofType.name &&
        edge.node.productCategory.name === pageData.category.name
    )
    const location = this.props

    return (
      <Layout location={this.props.location}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
        />
        <HeroSection>
          <Container>
            <BreadCrumb className="static">
              <Link to="/">Home </Link> /{" "}
              <span>
                {pageData.category.name === "Commercial Buildings"
                  ? "Commercial Metal Buildings"
                  : pageData.category.name}
              </span>
            </BreadCrumb>
            <HeroCover>
              <LeftHero>
                <HeroPageTitle>{pageData.heroTitle}</HeroPageTitle>
                <HeroDesc
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </LeftHero>
              <RightHero>
                <HeroFormWrap>
                  <HeroFormTitle>Get In Touch</HeroFormTitle>
                  <HeroFormBody>
                    <QuickContactForm
                      location={this.props.location}
                      formName={`${pageData.category.name} Contact Form`}
                    />
                  </HeroFormBody>
                </HeroFormWrap>
              </RightHero>
            </HeroCover>
          </Container>
        </HeroSection>

        {pageData.formSection &&
          pageData.formSection.features.length > 0 &&
          pageData.formSection.features[0].roof === "Vertical Roof" && (
            <ProductCustomBuildingForm
              data={pageData.formSection}
              buildingType={pageData.category.name}
              location={this.props.location}
              category={pageData.category.name}
            />
          )}
        {currentData.length !== 0 && (
          <SectionPage
            ept="80px"
            epb="80px"
            xpt="60px"
            xpb="60px"
            pt="40px"
            pb="40px"
            bg="#F4FBFF"
          >
            <Container>
              <SectionHeading textAlign="center">
                {pageData.productListTitle}
              </SectionHeading>
              {pageData.category.name === "Commercial Buildings" ||
              pageData.category.name === "Storage Buildings" ||
              pageData.category.name === "Steel Buildings" ||
              pageData.category.name === "Metal Warehouses" ? (
                <ProductCardList
                  data={currentData}
                  location={this.props.location}
                />
              ) : (
                <TabProductCardList
                  data={currentData}
                  category={pageData.category.name}
                  location={this.props.location}
                />
              )}
            </Container>
          </SectionPage>
        )}

        <CallToAction location={location} />
        <SectionPage
          ept="80px"
          epb="80px"
          xpt="60px"
          xpb="60px"
          pt="40px"
          pb="40px"
          bg="#fff"
        >
          <Container>
            <MainPanel>
              <LeftPanel>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: generateHTML(pageData.content.content),
                  }}
                />
              </LeftPanel>
              <RightPanel>
                <SidebarSticky className="isSticky">
                  <DesignPlan />
                  <AccreditedBusiness />
                </SidebarSticky>
              </RightPanel>
            </MainPanel>
          </Container>
        </SectionPage>
      </Layout>
    )
  }
}

export default ProductCategoryTemplate

export const pageQuery = graphql`
  query categoryLandigQuery($id: String!) {
    contentfulProductCategoryLanding(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      productListTitle
      formSection {
        title
        features {
          title
          roof
          image
          description
        }
      }
      category {
        name
      }
      content {
        content
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          description {
            description
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
        }
      }
    }
  }
`
