import React from 'react'
import styled from 'styled-components'

const BaseButton = styled.button`
	  position: relative;
	  display: ${props=>props.display};
	  width: ${props=>props.width};
		max-width: ${props=>props.maxWidth};
		background: #010920;
	  overflow: hidden;
	  height: 64px;
	  border: 2px solid #010920;
		border-radius: 10px;
		text-align: center;
		margin: auto;
	  transition: 0.5s ease-in-out;
	  & :focus {
	  	outline: none;
	  }
	  & a {
	  	text-decoration: none;
	  	color: white;
	  	font-family: Chakra Petch;
	  }
	  & div {
	  	z-index: 10;
	  	padding: 15px;
			color: white;
			font-size: 24px;
			line-height: 29px;
			font-family: Chakra Petch;
			font-weight: 700;
			position: relative;
			transition: 0.5s ease-in-out;
	  }
	  &:before,
	  &:after {
	    content: '';
	   	position: absolute;
	   	transition: 0.5s ease-in-out;
	   	z-index: 1;
	   	top: 0;
	  }

	  &:before {
	    left: 0px;
	    border-right: 50px solid transparent;
	    border-top: 64px solid #1E4156;
	    width: calc(50% + 26px);
	    transform: translateX(0%);
	    transition: 0.5s ease-in-out;
	  }
	  
	  &:after {
	    right: 0px;
	    border-left: 50px solid transparent;
	    border-bottom: 64px solid #062C44;
	  	width: calc(50% + 26px);
	    transform: translateX(0%);
	    transition: 0.5s ease-in-out;
	  }

	  &:hover {
	  	border: 2px solid #ED602B;
	  	cursor: pointer;
	  	background: #ED602B;	
	  	& div {
	  		color: white;	
	  	}
	    &:before { 
	    	transform: translateX(-100%);
	    	transition: 0.5s ease-in-out;
	    }
	    &:after { 
	    	transform: translateX(100%);
	    	transition: 0.5s ease-in-out;
	    }
	  }
	@media(max-width: 1024px) {
		& div {
			font-size: ${props=>props.mFontSize};
			padding: 15px 5px;
		}
		&:hover {
			border: 2px solid #010920;
			background: #010920;
			& div {
				color: white;
			}
			&:before { 
	    	transform: none;
	    }
	    &:after { 
	    	transform: none;
	    }
		}
	}
	@media(max-width: 480px) {
		max-width: 300px !important;
		width: 100%;
		margin-bottom: 20px;
		
		&:hover,
		&:focus,
		&:active {
			border: 2px solid #010920;
			background: #010920;
			& div {
				color: white;
			}
			&:before { 
				transform: none;
			}
			&:after { 
				transform: none;
			}
		}
	}
`

BaseButton.defaultProps = {
	width: "350px",
	display: "block"
}


class SectionButton extends React.Component {
	render(){
		const { text, width, children, maxWidth, display, mFontSize } = this.props
		return(
			<BaseButton  width={width} maxWidth={maxWidth} display={display} mFontSize={mFontSize}>
				<div>
				{children} {text}
				</div>
			</BaseButton>
		)
	}
}

export default SectionButton
