import React from "react"
import styled from "styled-components"
import FirstStepBar from "../VerticalStepBar/FirstStepBar"
import {ListBadge,Label, ControlLabel, StepGrid, StepLeftGrid, StepRightGrid} from "../FormStyle"
import { SectionTitle, Field, StaticText } from "../Section"

const Wrapper = styled.div`
  display: ${props => (props.hidden ? "none" : "block")};
`

const Description = styled.div`
  display: ${props => props.display};
`
class FirstStep extends React.Component {
  render() {
    const { data, hidden, rooftype } = this.props

    return (
      <Wrapper hidden={hidden}>
        <SectionTitle color="#fff" textAlign="center">{data.title}</SectionTitle>
        <StepGrid>
          <StepLeftGrid><FirstStepBar fill="#fff" /></StepLeftGrid>
          <StepRightGrid>
            <Field>
              <ControlLabel color="#fff">Select a Roof Style</ControlLabel>
              <ListBadge>
                <Label
                  className="badge"
                  bg={rooftype === "Vertical Roof" ? "#FF3E00" : "none"}
                  borderColor={rooftype === "Vertical Roof" ? "#FF3E00" : "rgba(255,255,255,0.2)"}
                  color={rooftype === "Vertical Roof" ? "#fff" : "rgba(255,255,255,0.2)"}
                >
                  <input
                    type="radio"
                    name="rooftype"
                    value="Vertical Roof"
                    onChange={this.props.handleChange}
                  />
                  Vertical Roof
                </Label>
                <Label
                  className="badge"
                  bg={rooftype === "A-Frame Roof" ? "#FF3E00" : "none"}
                  borderColor={rooftype === "A-Frame Roof" ? "#FF3E00" : "rgba(255,255,255,0.2)"}
                  color={rooftype === "A-Frame Roof" ? "#fff" : "rgba(255,255,255,0.2)"}
                >
                  <input
                    type="radio"
                    name="rooftype"
                    value="A-Frame Roof"
                    onChange={this.props.handleChange}
                  />
                  A-Frame Roof
                </Label>
                <Label
                  className="badge"
                  bg={rooftype === "Regular Roof" ? "#FF3E00" : "none"}
                  borderColor={rooftype === "Regular Roof" ? "#FF3E00" : "rgba(255,255,255,0.2)"}
                  color={rooftype === "Regular Roof" ? "#fff" : "rgba(255,255,255,0.2)"}
                >
                  <input
                    type="radio"
                    name="rooftype"
                    value="Regular Roof"
                    onChange={this.props.handleChange}
                  />
                  Regular Roof
                </Label>
              </ListBadge>
            </Field>
            <div>
              {data.features.map((item, i) => {
                return (
                  <Description display={item.roof === rooftype ? "block" : "none"} key={i}>
                    <Field>
                      <ControlLabel color="#fff">{item.title}</ControlLabel>
                      <StaticText color="#fff">{item.description}</StaticText>
                    </Field>
                  </Description>
                )
              })}
            </div>
          </StepRightGrid>
        </StepGrid>        
      </Wrapper>
    )
  }
}

export default FirstStep
